<template>
  <div class="error_wrap">
    <div class="response success fadein" ref="response" v-show="response">
      {{ response }}
    </div>
    <div class="response error fadein" ref="errors" v-show="errors.length">
      <template v-for="(error, idx) in errors" :key="idx">
        <template v-if="errors.length > 1">
          <li>{{ error }}</li>
        </template>
        <template v-else>
          {{ error }}
        </template>
      </template>
    </div>
    <div class="loader" v-if="loading"></div>
  </div>
</template>

<script>
import axios from "axios";
import cfg_axios from "@/config/cfg_axios";

export default {
  name: "Activate",
  data() {
    return {
      response: null,
      errors: [],
      loading: false,
    };
  },
  props: {
    md5: {
      required: true,
      type: String,
    },
  },
  mounted() {
    this.loading = true;
    this.response = null;
    this.errors = [];

    if (!this.$props.md5) {
      this.errors.push("Activation hash not provided");
      this.loading = false;
      return;
    }

    axios
      .get(cfg_axios.base_url + "/activate/" + this.$props.md5, {
        headers: cfg_axios.headers,
      })
      .then((response) => {
        this.response = response.data;
      })
      .catch((error) => {
        this.errors.push(
          error.response.data.messages[
            Object.keys(error.response.data.messages)[0]
          ]
        );
      })
      .finally(() => {
        setTimeout(() => {
          this.$store.dispatch("close_modal");
          this.$router.push({ path: "/" });
        }, 10000);
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.error_wrap {
  margin-top: 10px;
}
</style>
